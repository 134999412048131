import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem('userData') &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  )
}

// export const checkAppUpdate = (value1, value2) => {
//   useJwt.http.get('app_update').then(response => {
//     const { update_status } = response.data.app_updates
//     console.log('update_status:', update_status)
//     if (update_status === value1) {
//       try {
//         this.$swal({
//           title: 'System Telah Diperbarui',
//           text: '🙏 anda akan otomatis logout. 🙏',
//           // eslint-disable-next-line global-require
//           imageUrl: require('@/assets/images/pages/not-authorized.svg'),
//           // imageWidth: 402,
//           // imageHeight: 472,
//           imageAlt: 'system_update',
//           confirmButtonText: 'CLOSE',
//           showCancelButton: false,
//           customClass: {
//             confirmButton: 'btn btn-success',
//             cancelButton: 'btn btn-outline-danger ml-1',
//           },

//           buttonsStyling: false,
//         }).then(async () => {
//           useJwt.http.put('app_update/next/logout').then(() => {
//             this.$router.go()
//           })
//         })
//       } catch (err) {
//         console.log('sweet', err)
//       }
//     } else if (update_status === value2) {
//       console.log('user automaticaly logout:', update_status)

//       localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
//       localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
//       localStorage.removeItem('userData')

//       // Redirect to login page
//       // this.$router.go()
//       this.$router.push({ name: 'auth-login' })
//     }
//   }).catch(err => {
//     //
//   })
// }

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getStorageAdvanceSearch = () =>
  JSON.parse(localStorage.getItem('advanceSearchData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'superadmin') return '/sa/users'
  if (['client', 'host', 'security', 'viewer', 'jetty man'].includes(userRole))
    return '/sa/guide'
  return { name: 'auth-login' }
}
