import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = (next) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer'
    ) {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}
const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer'
    ) {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'auth-login' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/data-master/shipping-agent',
    name: 'data-master-shipping-agent',
    component: () => import('@/views/pages/datamaster/ShippingAgent.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: { guard }
  },
  {
    path: '/data-master/river-boat-tbbm',
    name: 'data-master-river-boat-tbbm',
    component: () => import('@/views/pages/datamaster/RiverBoatTBBM.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: { guard }
  },
  {
    path: '/data-master/river-boat-hulu_migas',
    name: 'data-master-river-boat-hulu_migas',
    component: () => import('@/views/pages/datamaster/RiverBoatHM.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: { guard }
  },
  {
    path: '/data-master/speed-boat',
    name: 'data-master-speed-boat',
    component: () => import('@/views/pages/datamaster/SpeedBoat.vue'),
    meta: { guard }
  },
  {
    path: '/data-master/company-bongkar-muat',
    name: 'data-master-company-bongkar-muat',
    component: () => import('@/views/pages/datamaster/CompanyBongkarMuat.vue'),
    meta: { guard }
  },
  {
    path: '/data-master/land-vehicles',
    name: 'data-master-land-vehicles',
    component: () =>
      import('@/views/pages/datamaster/landvehicle/LandVehicle.vue'),
    meta: { guard }
  },
  {
    path: '/data-master/person/v1',
    name: 'data-master-person-v1',
    component: () => import('@/views/pages/datamaster/person/Person.vue'),
    meta: { guard }
  }
]
