import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = (next) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (role === 'client' || role === 'host' || role === 'security') {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}
const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer' ||
      role === 'jetty man'
    ) {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'misc-not-authorized' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}
const guardCustomNoViewerCanEnter = (
  next,
  myAbilitySubject = [],
  myAbilityAction = []
) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      // role === 'viewer' ||
      role === 'jetty man'
    ) {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'misc-not-authorized' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/dashboard/monitoring/rrv/tbbm',
    name: 'dashboard-monitoring-rrv-tbbm',
    component: () =>
      import('@/views/pages/monitoring/jalur-sungai/tbbm/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'TBBM',
          active: true
        },
        {
          text: 'index',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'special', 'jetty'],
          ['SAL', 'TBBM', 'approvement']
        )
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tbbm/create',
    name: 'dashboard-monitoring-rrv-tbbm-create',
    component: () =>
      import('@/views/pages/monitoring/jalur-sungai/tbbm/wizard/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'TBBM',
          to: '/dashboard/monitoring/rrv/tbbm'
        },
        {
          text: 'Buat Rencana Kedatangan Kapal',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'special', 'qrcode'],
          ['SAL', 'TBBM', 'approvement']
        )
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tbbm/view',
    name: 'dashboard-monitoring-rrv-tbbm-view',
    component: () =>
      import('@/views/pages/monitoring/jalur-sungai/tbbm/wizard/index.vue'),

    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'TBBM',
          to: '/dashboard/monitoring/rrv/tbbm'
        },
        {
          text: 'View Rencana Kedatangan Kapal',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'special', 'qrcode', 'jetty'],
          ['SAL', 'TBBM', 'approvement']
        )
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tbbm/summary',
    name: 'dashboard-monitoring-rrv-tbbm-summary',
    component: () =>
      import('@/views/pages/monitoring/jalur-sungai/tbbm/summary/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'TBBM',
          to: '/dashboard/monitoring/rrv/tbbm'
        },
        {
          text: 'Summary',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'special', 'qrcode', 'jetty'],
          ['SAL', 'TBBM', 'approvement']
        )
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tbbm/j3a',
    name: 'dashboard-monitoring-rrv-tbbm-j3a',
    component: () =>
      import('@/views/pages/monitoring/jalur-sungai/tbbm-j3a/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'JETTY 3A',
          active: true
        },
        {
          text: 'index',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'jetty'], ['SAL', 'TBBM JETTY 3A'])
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tbbm/j3a/create',
    name: 'dashboard-monitoring-rrv-tbbm-j3a-create',
    component: () =>
      import('@/views/pages/monitoring/jalur-sungai/tbbm-j3a/wizard/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'JETTY 3A',
          to: '/dashboard/monitoring/rrv/tbbm/j3a'
        },
        {
          text: 'Buat Rencana Kedatangan Kapal',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode'], ['SAL', 'TBBM JETTY 3A'])
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tbbm/j3a/view',
    name: 'dashboard-monitoring-rrv-tbbm-j3a-view',
    component: () =>
      import('@/views/pages/monitoring/jalur-sungai/tbbm-j3a/wizard/index.vue'),

    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'JETTY 3A',
          to: '/dashboard/monitoring/rrv/tbbm/j3a'
        },
        {
          text: 'View Rencana Kedatangan Kapal',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['SAL', 'TBBM JETTY 3A'])
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tbbm/j3a/summary',
    name: 'dashboard-monitoring-rrv-tbbm-j3a-summary',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-sungai/tbbm-j3a/summary/index.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'JETTY 3A',
          to: '/dashboard/monitoring/rrv/tbbm/j3a'
        },
        {
          text: 'Summary',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['SAL', 'TBBM JETTY 3A'])
    }
  },
  {
    path: '/dashboard/monitoring/rrv/hulu-migas',
    name: 'dashboard-monitoring-rrv-hulu-migas',
    component: () =>
      import('@/views/pages/monitoring/jalur-sungai/hulu-migas/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'Hulu Migas',
          active: true
        },
        {
          text: 'index',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['Hulu Migas', 'SAL'])
    }
  },
  {
    path: '/dashboard/monitoring/rrv/hulu-migas/create',
    name: 'dashboard-monitoring-rrv-hulu-migas-create',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-sungai/hulu-migas/wizard/index.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'Hulu Migas',
          to: '/dashboard/monitoring/rrv/hulu-migas'
        },
        {
          text: 'Buat Rencana Kedatangan Kapal',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode'], ['Hulu Migas', 'SAL'])
    }
  },
  {
    path: '/dashboard/monitoring/rrv/hulu-migas/view',
    name: 'dashboard-monitoring-rrv-hulu-migas-view',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-sungai/hulu-migas/wizard/index.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'Hulu Migas',
          to: '/dashboard/monitoring/rrv/hulu-migas'
        },
        {
          text: 'View Rencana Kedatangan Kapal',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['Hulu Migas', 'SAL'])
    }
  },
  {
    path: '/dashboard/monitoring/rrv/hulu-migas/summary/:id',
    name: 'dashboard-monitoring-rrv-hulu-migas-summary',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-sungai/hulu-migas/summary/index.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'Hulu Migas',
          to: '/dashboard/monitoring/rrv/hulu-migas'
        },
        {
          text: 'Summary',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['SAL', 'Hulu Migas'])
    }
  },
  {
    path: '/analytic/jalur-sungai/jetty-view',
    name: 'analytic-jalur-sungai-jetty-view',
    component: () =>
      import('@/views/pages/analytic/jalur-sungai/jetty-view/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Analytic',
      breadcrumb: [
        {
          text: '⚓️',
          active: true
        },
        {
          text: 'Jetty View',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'qrcode', 'special', 'jetty'],
          ['Hulu Migas', 'SAL', 'TBBM', 'approvement']
        )
    }
  },
  {
    path: '/analytic/jalur-sungai/jetty-view/j3a',
    name: 'analytic-jalur-sungai-jetty-view-j3a',
    component: () =>
      import('@/views/pages/analytic/jalur-sungai/jetty-view-j3a/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Analytic',
      breadcrumb: [
        {
          text: '⚓️',
          active: true
        },
        {
          text: 'Jetty View 3A',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'qrcode', 'special', 'jetty'],
          ['Hulu Migas', 'SAL', 'TBBM JETTY 3A', 'approvement']
        )
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tug-boat/view',
    name: 'dashboard-monitoring-rrv-tug-boat-view',
    // component: () =>
    //   import('@/views/pages/dashboard/createOrEditRiver/CreateTugBoat.vue'),
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-sungai/component/wizard/TugBoatWizard.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'special', 'qrcode', 'jetty'],
          ['SAL', 'TBBM', 'Hulu Migas', 'approvement']
        )
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tug/boat/summary',
    name: 'dashboard-monitoring-rrv-tug-boat-summary',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-sungai/component/summary/TBSummary.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'TugBoat',
          active: true
        },
        {
          text: 'Summary',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'special', 'qrcode', 'jetty'],
          ['SAL', 'TBBM', 'Hulu Migas', 'approvement']
        )
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tug-boat/view/j3a',
    name: 'dashboard-monitoring-rrv-tug-boat-view-j3a',
    // component: () =>
    //   import('@/views/pages/dashboard/createOrEditRiver/CreateTugBoat.vue'),
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-sungai/tbbm-j3a/wizard/TugBoatWizard.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'TugBoat (JETTY 3A)',
          to: '/dashboard/monitoring/rrv/tbbm/j3a'
        },
        {
          text: 'View Rencana Kedatangan Kapal',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['SAL', 'TBBM JETTY 3A'])
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tug/boat/summary/j3a',
    name: 'dashboard-monitoring-rrv-tug-boat-summary-j3a',
    component: () =>
      import(
        '@/views/pages/monitoring/jalur-sungai/tbbm-j3a/summary/TBSummary.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Monitoring',
      breadcrumb: [
        {
          text: 'Jalur Sungai',
          active: true
        },
        {
          text: 'TugBoat (JETTY 3A)',
          to: '/dashboard/monitoring/rrv/tbbm/j3a'
        },
        {
          text: 'Summary',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['SAL', 'TBBM JETTY 3A'])
    }
  },

  {
    path: '/dashboard/monitoring/rrv/form-tug-boat',
    name: 'dashboard-monitoring-rrv-form-tug-boat',
    component: () =>
      import('@/views/pages/dashboard/RiverRouteFormTugBoat.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      guard: (next) =>
        guardCustom(
          next,
          ['all', 'qrcode', 'jetty'],
          ['Hulu Migas', 'SAL', 'TBBM']
        )
    }
  },
  {
    path: '/dashboard/monitoring/rrv/tug-boat',
    name: 'dashboard-monitoring-rrv-tug-boat',
    component: () => import('@/views/pages/dashboard/RiverRouteTugBoat.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      guard: (next) =>
        guardCustomNoViewerCanEnter(
          next,
          ['all', 'qrcode', 'jetty'],
          ['Hulu Migas', 'SAL', 'TBBM', 'approvement']
        )
    }
  },

  {
    path: '/dashboard/monitoring/rrv/speed-boat',
    name: 'dashboard-monitoring-rrv-speed-boat',
    component: () => import('@/views/pages/dashboard/RiverRouteSpeedBoat.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      guard: (next) =>
        guardCustom(next, ['all', 'qrcode', 'jetty'], ['Hulu Migas', 'SAL'])
    }
  }
]
